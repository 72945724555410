import React from 'react';

class Evaluator extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            inputValue: '',
            additionalInputs: [],
            columnHeadings: []
        }

        this.evaluateChildScript()
    }

    evaluateChildScript = () => {
        this.scope = new Function(this.props.children)()
    }

    componentDidMount () {
        this.setState({
            inputValue: this.props.initialinput,
            additionalInputs: eval(this.props.additionalinputs),
            columnHeadings: eval(this.props.columnheadings),
        })
    }

    render () {
        return (
            <table style={{
                backgroundColor: '#31435f',
                color: '#fff',
                marginTop: '1rem',
                borderRadius: '0.3em',
                padding: '1em',
                width: '100%',
                tableLayout: 'fixed'
            }}>
                <tr style={{
                    fontWeight: 'bold'
                }}>
                    {this.state.columnHeadings.map( (heading, i) => (
                        <td>{heading}</td>
                    ))}
                </tr>
                {this.state.additionalInputs.map( (argument, i) => (
                    <tr>
                        {i === 0 && (
                            <td rowSpan={this.state.additionalInputs.length}>
                                <input value={this.state.inputValue} onChange={e => this.setState({ inputValue: e.target.value })} style={{
                                    padding: '0.5rem'
                                }}>
                                </input>
                            </td>
                        )}
                        <td>
                            {argument}
                        </td>
                        <td>
                            {this.scope['encrypt'](this.state.inputValue, ...argument)}
                        </td>
                    </tr>
                ))}
            </table>
        )
    }
}

export default Evaluator